<template>
  <div class="dtc-upload">
    <main class="del-files" v-if="askdel">
      <div class="del-file">
        <header>
          <img src="warning.svg" />
        </header>
        <footer>
          <h6>清除全部檔案？</h6>
          <nav>
            <button>確定</button>
            <button @click="askdel = false">取消</button>
          </nav>
        </footer>
      </div>
    </main>
    <input
      type="file"
      id="uploadMyFile"
      @change="fileChange($event)"
      hidden
      accept=".xlsx, .xls"
    />
    <header class="ask-header">PGY資料上傳</header>

    <main class="main-upload">
      <div>
        <!-- <div class="excel-example">
          <a :href="href" target="_blank">上傳Excel樣式樣板下載</a>
        </div> -->
        <nav>
          <template v-if="!uploading">
            <img
              src="upload.svg"
              width="130"
              height="165"
              @click="clickFileBtn"
            />
            <h6 style="text-align:center;">檔案格式: XLSX</h6>
            <h6 v-if="!selectedFileName" style="text-align:center;">
              未選擇任何檔案
            </h6>
            <h6 v-if="selectedFileName" style="text-align:center;">
              {{ selectedFileName }}
            </h6>
          </template>
          <template v-if="uploading && !uploadOk && !uploadFail">
            <span class="ani-spin">
              <img src="refresh.svg" width="130" height="165" />
            </span>
            <h6 style="text-align:center;color:#10579B;">檔案上傳中</h6>
            <h6 style="text-align:center;color:#10579B;">請稍候...</h6>
          </template>
          <template v-if="uploadFail">
            <img src="error.svg" width="130" height="165" />
            <h6 style="text-align:center;color:#D87676;">上傳失敗</h6>
            <h6 style="text-align:center;color:#D87676">
              失敗原因:API錯誤 {{ errorsMsg }}
            </h6>
            <b-button
              size="sm"
              variant="danger"
              class="mb-3"
              @click="uploadingOther"
              >上傳其他檔案</b-button
            >
          </template>
          <template v-if="uploadOk">
            <img src="success.svg" width="130" height="165" />
            <h6 style="text-align:center;color:#2E8301;">
              上傳成功 {{ sucessMsg }}
            </h6>
            <b-button
              size="sm"
              variant="warning"
              class="mb-3"
              @click="uploadingOther"
              >上傳其他檔案</b-button
            >
          </template>
          <!-- <main class="select">
            <b-select :options="years" v-model="year"></b-select>
            <b-select :options="types" v-model="type"></b-select>
          </main> -->
          <footer v-if="!uploading">
            <b-button size="sm" variant="info" @click="clickFileBtn"
              >選擇上傳檔案</b-button
            >
            <b-button
              size="sm"
              variant="primary"
              @click="uploadFile"
              :disabled="!selectedFileName"
              >確認上傳</b-button
            >
          </footer>
        </nav>
      </div>
      <div>
        <h5>
          上傳檔案成功
          <span class="clear-all-file" @click="delOkFiles" hidden
            >全部清除</span
          >
        </h5>

        <main v-if="!leftItems.length" class="dtc-grid-header main-dtc-body">
          <div
            style="
              grid-column: 1 / -1;
              padding: 20px 0px;
              font-size: 24px;
              color: #000;
            "
          >
            暫無資料
          </div>
        </main>
        <div class="overflow-auto" v-if="leftItems.length">
          <b-table
            id="myOkTable"
            :items="leftItems"
            :per-page="leftPerPage"
            :current-page="leftCurrentPage"
            small
          ></b-table>
          <b-pagination
            v-model="leftCurrentPage"
            :total-rows="leftRows"
            :per-page="leftPerPage"
            aria-controls="myOkTable"
          ></b-pagination>
        </div>
      </div>
      <div>
        <h5>
          上傳檔案失敗
          <span class="clear-all-file mr-2" @click="delFailFiles" hidden
            >全部清除</span
          >
        </h5>

        <main v-if="!rightItems.length" class="dtc-grid-header main-dtc-body">
          <div
            style="
              grid-column: 1 / -1;
              padding: 20px 0px;
              font-size: 24px;
              color: #000;
            "
          >
            暫無資料
          </div>
        </main>
        <div class="overflow-auto" v-if="rightItems.length">
          <b-table
            id="myErrorTable"
            :items="rightItems"
            :per-page="rightPerPage"
            :current-page="rightCurrentPage"
            small
          ></b-table>
          <b-pagination
            v-model="rightCurrentPage"
            :total-rows="rightRows"
            :per-page="rightPerPage"
            aria-controls="myErrorTable"
          ></b-pagination>
        </div>
      </div>
    </main>
  </div>
</template>
<style lang="scss" scoped></style>
<script>
import { store, actions } from "@/store/global.js";
let thisYear = new Date();
thisYear = thisYear.getFullYear() - 1911;
const years = [
  // {
  //   text: "上傳年度(必填)",
  //   value: null,
  //   disabled: true,
  // },
  {
    text: thisYear + 1,
    value: thisYear + 1,
  },
  {
    text: thisYear,
    value: thisYear,
  },
  {
    text: thisYear - 1,
    value: thisYear - 1,
  },
  {
    text: thisYear - 2,
    value: thisYear - 2,
  },
  {
    text: thisYear - 3,
    value: thisYear - 3,
  },
  {
    text: thisYear - 4,
    value: thisYear - 4,
  },
];
const types = [
  // {
  //   value: null,
  //   text: "上傳類型(必填)",

  //   disabled: true,
  // },
  { value: "1", text: "學士" },
  { value: "2", text: "碩士" },
];

export default {
  data() {
    return {
      href: "",
      tab: 1,
      errors: "",
      curFile: "",
      selectedFileName: "",
      askdel: false,
      uploading: false,
      uploadOk: "",
      uploadFail: "",
      oks: [],
      errors: [],
      names: [],
      name: "",
      years,
      year: new Date().getFullYear() - 1911,
      thisYear,
      types,
      type: 1,
      rightPerPage: 10,
      rightCurrentPage: 1,
      rightItems: [],
      leftPerPage: 10,
      leftCurrentPage: 1,
      leftItems: [],
      errorsMsg: "",
      sucessMsg: "",
    };
  },
  components: {},
  methods: {
    uploadingOther() {
      this.uploading = false;
      this.uploadFail = "";
      this.uploadOk = "";
      this.selectedFileName = "";
      this.rightItems = [];
      this.leftItems = [];
      this.year = new Date().getFullYear() - 1911;
      this.rightCurrentPage = 1;
      this.leftCurrentPage = 1;
      this.reloadFunc();
    },
    reloadFunc() {
      window.location.reload();
    },
    delOkFiles() {
      this.askdel = true;
    },
    delFailFiles() {
      this.askdel = true;
    },
    clickFileDel() {
      this.selectedFileName = "";
    },
    clickFileBtn() {
      document.querySelector("#uploadMyFile").click();
    },
    fileChange(evt) {
      const curFile = evt.target.files[0];
      this.curFile = curFile;
      //file name
      this.selectedFileName = this.curFile.name;
      //alert(this.selectedFileName);
    },
    async uploadFile() {
      this.uploading = true;
      let form = new FormData();
      form.append("input", this.curFile);
      //   form.append("BYear", "" + this.year);
      //   form.append("Type", "" + this.type);
      //const name = this.names.find(s => s.value == this.name).text;
      //form.append("docimaster", this.name);
      // alert(this.year);

      try {
        const ret = await window.axios.post("StudentTraining/ImportFile", form);

        // step1 : get ok list
        // this.oks = ret.Sucess;
        if (ret.Sucess.length) {
          this.leftItems = ret.Sucess.map((s) => ({
            項次: s.Item,
            姓名: s.Name,
            准考證編號: s.Identifier,
          }));
          this.leftRows = ret.Sucess.length;
        }

        // step2 : get error list
        // this.errors = ret.Error;
        if (ret.Error.length) {
          this.rightItems = ret.Error.map((s) => ({
            項次: s.Item,
            姓名: s.Name,
            准考證編號: s.Identifier,
            失敗原因: s.ErrMessage,
          }));
          this.rightRows = ret.Error.length;
        }
        this.sucessMsg = `-成功上傳筆數共${ret.Sucess.length}筆`;
        this.uploadOk = "上傳成功";
        this.curFile = "";
      } catch (e) {
        this.errorsMsg = `- ${e.response.data.Message}`;
        this.uploadFail = "上傳失敗";
      }
    },
  },
  async mounted() {
    // try {
    //   const { Url } = await window.axios.get(`File/GetImportSample?kind=2`);
    //   this.href = Url ? `${Url}` : "";
    // } catch (e) {
    //   alert("" + e);
    // }
  },
};
</script>
<style lang="scss" scoped>
.dtc-upload {
  color: white;
  background: white;
  min-height: 100vh;
}
.ask-header {
  background: #646b74;
  height: 42px;
  color: white;
  font-size: 20px;
  padding-left: 10px;
  line-height: 42px;
  margin-bottom: 12px;
  width: 100vw;
}
.main-upload {
  margin: 20px;
  display: grid;
  grid-template-columns: 420px 560px 1fr;
  grid-gap: 2rem;
  > div {
    display: flex;
    flex-direction: column;
    h5 {
      font-weight: bold;
      color: #404040;
      font-size: 20px;
      margin-bottom: 10px;
    }
  }
  > div:first-child {
    min-height: calc(100vh - 200px);
    background: #f4f4f4 0% 0% no-repeat padding-box;
    border-radius: 10px;
    display: grid;
    place-items: center;
    margin-top: 40px;
    position: relative;
    nav {
      display: flex;
      flex-direction: column;
      position: relative;
      transform: translateY(-50px);
      align-items: center;
      footer {
        // position: absolute;
        // bottom: -120px;
        // left: -50%;
        display: grid;
        grid-template-columns: repeat(3, max-content);
        grid-gap: 10px;
      }
      .select {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
      }
    }
    h6 {
      color: #7e7e7e;
      font-size: 16px;
      margin-top: 10px;
    }
    button {
      margin-top: 10px;
    }
  }
}
::v-deep .btn-primary {
  background: #1f7cd3 0% 0% no-repeat padding-box;
  border-radius: 4px;
}

::v-deep .btn-info {
  background: #10579b;
  border-color: #10579b;
  border-radius: 4px;
}
::v-deep .btn-danger {
  background: #d87676;
  border-color: #d87676;
  border-radius: 4px;
}

::v-deep .btn-warning {
  background: #2e8301;
  border-color: #2e8301;
  border-radius: 4px;
  color: white;
}

.dtc-grid-header-ok,
.dtc-grid-header-fail {
  display: grid;
  grid-template-columns: 120px 220px 1fr;
  grid-auto-flow: column;
  text-align: center;
  padding-right: 0px;
  border: 1px solid white;
  > div {
    border-right: 1px solid white;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
  }
  > div:last-child {
    border-right: none;
  }
}
.dtc-grid-header-fail {
  grid-template-columns: 120px 120px 120px 1fr;
}
.my-dark {
  > div {
    background: var(--gray);
    color: white;
  }
}
.main-dtc-body {
  &:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }
  > div {
    line-height: 24px;
  }
}
.clear-all-file {
  display: inline-block;
  width: 80px;
  height: 24px;
  background: #efefef !important;
  border-radius: 12px;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  transform: translate(5px, -3px);
  cursor: pointer;
}
.del-files {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(#000, 0.8);
  display: grid;
  place-items: center;
  z-index: 8;
  .del-file {
    display: flex;
    flex-direction: column;
    width: 410px;
    height: 234px;
    background: white;
    border-radius: 10px;
    header {
      height: 93px;
      background: #dd6060;
      text-align: center;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      img {
        width: 68px;
        height: 61px;
        display: inline-block;
        margin-top: 14px;
        //padding-top: 20px;
      }
    }
    footer {
      padding-top: 10px;
      text-align: center;
      h6 {
        font-size: 22px;
        color: #363636;
        margin-bottom: 1.4rem;
        margin-top: 1rem;
      }
      nav {
        display: inline-block;
        button {
          all: unset;
          cursor: pointer;
          width: 128px;
          height: 43px;
          border-radius: 23px;
          color: #ffffff;
          text-align: center;
          line-height: 43px;
          background: #a0a0a0;
          display: inline-block;
        }
        button:first-child {
          background: #dd6060;
          margin-right: 1rem;
        }
      }
    }
  }
}
.ani-spin {
  animation: spinIcon 1s infinite ease-out;
}

@keyframes spinIcon {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
.name-list {
  position: absolute;
  top: -50px;
  left: 0;
  width: 400px;
  height: 40px;
}
::v-deep .table > thead > tr {
  background: #6c757d;
  color: #fff;

  text-align: center;
  th {
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
  }
}

::v-deep .table > tbody > tr:nth-child(even) {
  background-color: #f5f5f5;
  text-align: center;
  td {
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
  }
}
::v-deep .table > tbody > tr:nth-child(odd) {
  background-color: #e0e0e0;
  text-align: center;
  td {
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
  }
}
.excel-example {
  color: #d87676;
  position: absolute;
  top: -40px;
  left: 0;
  font-size: 18px;
  font-weight: 900;
}
a {
  color: #10579b;
  text-decoration: none;
  background-color: transparent;
}
</style>
