<template>
  <section class="dtc-main-section">
    <vue-tabs v-model="tabName">
      <v-tab
        v-for="(item, i) in titles"
        :title="item.title"
        :key="item.comps + i"
      >
        <component
          v-if="tabName === item.title"
          :is="item.comps"
          :tabName="item.comps"
        />
      </v-tab>
    </vue-tabs>
  </section>
</template>

<script>
import { store } from "@/store/global.js";
import { VueTabs, VTab } from "vue-nav-tabs";
import "vue-nav-tabs/themes/vue-tabs.css";
import LocalProgressmanage from "./LocalProgressmanage";
import RuralProgressmanage from "./RuralProgressmanage";
import UploadPGYData from "./UploadPGYData";
import FocusProgressmanage from "./FocusProgressmanage";

export default {
  name: "totalSpendRuralTabsVue",
  data() {
    return {
      showTab1: false,
      show: true,
      tabName: "",
      allUserRight: [],
      userRights: [],
      mainTitle: "「服務歷程管理」",
      titles: [
        {
          title: "服務歷程-在地養成公費生",
          comps: "LocalProgressmanage",
          isActive: false,
          functionId: "40031",
        },
        {
          title: "服務歷程-偏護菁英公費生",
          comps: "RuralProgressmanage",
          isActive: false,
          functionId: "40032",
        },
        {
          title: "PGY資料上傳",
          comps: "UploadPGYData",
          isActive: false,
          functionId: "30012",
        },
        {
          title: "服務歷程-重點科別培育公費生",
          comps: "FocusProgressmanage",
          isActive: false,
          functionId: "40036",
        },
      ],
    };
  },
  computed: {
    nowTab() {
      return store.nowTab;
    },
  },
  components: {
    VueTabs,
    VTab,
    LocalProgressmanage,
    RuralProgressmanage,
    UploadPGYData,
    FocusProgressmanage,
  },
  methods: {
    setUserRight() {
      let arr = Boolean(sessionStorage.functionIds)
        ? JSON.parse(sessionStorage.getItem("functionIds"))
        : [];
      this.titles = this.titles.map((s) => {
        s.isActive = arr.find((k) => k == s.functionId) ? true : false;
        return s;
      });
      this.titles = this.titles.filter((s) => s.isActive);
    },
  },
  created() {},
  beforeMount() {},
  mounted() {
    this.setUserRight();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.dtc-main-section {
  width: fit-content;
  //   height: 100vh;
  //   overflow-x: hidden;
}
iframe {
  width: 100vw;
  height: clamp(60vh, 90vh, 100vh);
}
</style>
